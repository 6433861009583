const ADD_INCOME = "ADD_INCOME";
const DELETE_INCOME = "DELETE_INCOME";
const CLEAR_INCOMES = "CLEAR_INCOMES";
const UPDATE_INCOME = "UPDATE_INCOME";

const ADD_EXPENSE = "ADD_EXPENSE";
const DELETE_EXPENSE = "DELETE_EXPENSE";
const CLEAR_EXPENSES = "CLEAR_EXPENSES";
const UPDATE_EXPENSE = "UPDATE_EXPENSE"

const LOAD_DEFAULT = "LOAD_DEFAULT";


const CHANGE_PAGE = "CHANGE_PAGE";

export {ADD_INCOME, ADD_EXPENSE, DELETE_INCOME, DELETE_EXPENSE, LOAD_DEFAULT, CLEAR_INCOMES, CLEAR_EXPENSES, CHANGE_PAGE, UPDATE_INCOME, UPDATE_EXPENSE};